var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{attrs:{"app":"","dense":"","flat":""}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-1",attrs:{"tile":"","text":"","to":"/","block":false}},on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-home")]),_vm._v(" DBHI ")],1)]}}])},[_c('span',[_vm._v("Home")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-1",attrs:{"tile":"","text":"","href":"pdf/FPGA2020_poster.pdf"}},on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-school")]),_vm._v(" FPGA 2020 ")],1)]}}])},[_c('span',[_vm._v("FPGA 2020")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-1",attrs:{"tile":"","text":"","href":"concept"}},on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-movie-open")]),_vm._v(" Concept ")],1)]}}])},[_c('span',[_vm._v("Concept")])]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"tile":"","text":""},on:{"click":function($event){_vm.$vuetify.theme.dark = !_vm.$vuetify.theme.dark}}},on),[_c('v-icon',[_vm._v("mdi-theme-light-dark")])],1)]}}])},[_c('span',[_vm._v("Switch light/dark")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }