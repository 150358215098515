<template>
  <v-container fill-height fluid>
    <v-row justify="center" no-gutters>
      <v-alert type="error"> <strong>404</strong> Page Not Found </v-alert>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'NotFound',
};
</script>
